<template>
  <div class="container mt-3">
    <div class="row"><h2>{{ $t('dashboard.dashboard') }}</h2></div>
    <div class="row mt-4">
      <div v-if="showHowToCreateCertificate" class="col-12 col-md-12 mb-3">
        <div class="card alert alert-success">
          <div class="card-body">
            <div class="mb-3">
              <div>1. Vyplňte správně váš profil.</div>

              <router-link :to="{ name: 'ClientProfile' }">
                <div class="btn btn-link text-success "><span class="text-decoration-underline">Vyplnit profil</span> ->
                </div>
              </router-link>

            </div>
            <div class="mb-3">
              <div>2. Nahrajte soubor do karty "Vytvoření certifikátu". Certifikát si zde můžete rovněž pojmenovat.</div>
            </div>
            <div>3. Klikněte na tlačítko "Vytvořit certifikát".</div>
          </div>
        </div>
      </div>
      <div v-if="!isLoading && !isAllDataForCert && !showHowToCreateCertificate" class="col-12 col-md-12 mb-3">
        <router-link :to="{ name: 'ClientProfile' }">
          <div class="card alert alert-danger">
            <div class="card-body">
              <h5>Nemáte vyplněný kompletní profil.</h5>
              <div>Pro správné vygenerování certifikátu prosím doplňte váš profil.
                <button class="btn btn-link text-danger"><span class="text-decoration-underline">Vyplnit profil</span> -></button>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-md-12 mb-3">
        <FileUploadComponent></FileUploadComponent>
      </div>
      <div class="col-12 col-md-12 mb-3">
        <CertsListComponent></CertsListComponent>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../http";

const EMPTY_CLIENT = {
  name: '',
  email: '',
  address: {
    line_1: '',
    line_2: '',
    city: '',
    country_code: '',
    postal_code: '',
  },
  verification: {
    document_type: '',
    document_number: '',
    expiration_date: '',
  },
};

export default {
  data() {
    return {
      client: {
        address: {
          line_1: '',
          line_2: '',
          city: '',
          country_code: '',
          postal_code: '',
        },
        verification: {
          document_type: '',
          document_number: '',
          expiration_date: '',
        },
      },
      isLoading: false,
      showHowToCreateCertificate: false,
    };
  },

  mounted() {
    this.getAuthClient();
    if (this.$route.query.showHowToCreateCertificate) {
      this.showHowToCreateCertificate = this.$route.query.showHowToCreateCertificate;
      console.log(this.showHowToCreateCertificate);
    }
  },

  computed: {
    isName() {
      return this.client.name && this.client.name.length !== 0;
    },

    isAddress() {
      return this.client.address.line_1.length !== 0 && this.client.address.city.length !== 0 && this.client.address.postal_code.length !== 0;
    },

    isAllVerificationData() {
      return this.client.verification && this.client.verification.document_type?.length !== 0 && this.client.verification.document_number?.length !== 0 && this.client.verification.expiration_date?.length !== 0;
    },

    isAllDataForCert() {
      return this.isName && this.isAddress && this.isAllVerificationData;
    }
  },

  methods: {
    getAuthClient() {
      let self = this;
      self.isLoading = true;
      http.get('/get-auth-client').then((response) => {
        self.client = response.data.client;
        if (!response.data.client.address) {
          self.client.address = {...EMPTY_CLIENT.address};
        }
        if (!response.data.client.verification) {
          self.client.verification = {...EMPTY_CLIENT.verification};
        }
        self.clientForModal = self.client;
        self.isLoading = false;
      });
    },
  }
};
</script>

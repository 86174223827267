import en_general from './en/general.json';
import en_dashboard from './en/dashboard.json';
import en_cert from './en/cert.json';
import en_client from './en/client.json';
import en_file from './en/file.json';
import cs_general from './cs/general.json';
import cs_dashboard from './cs/dashboard.json';
import cs_cert from './cs/cert.json';
import cs_client from './cs/client.json';
import cs_file from './cs/file.json';


//export const defaultLocale = 'en'

export const languages = {
  en: {
    general: en_general,
    dashboard: en_dashboard,
    cert: en_cert,
    client: en_client,
    file: en_file,

  },
  cs: {
    general: cs_general,
    dashboard: cs_dashboard,
    cert: cs_cert,
    client: cs_client,
    file: cs_file,
  }
}

import './bootstrap';
import Bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';

// import '../css/app.css'

/* import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
window.Alpine = Alpine;

Alpine.plugin(focus);

Alpine.start(); */

import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { languages } from './locales/index.js';

import App from './App.vue';
import router from './router';
import mitt from 'mitt';
import DropZone from 'dropzone-vue';
import VueSweetalert2 from 'vue-sweetalert2';
import { Skeletor } from 'vue-skeletor';

// components
import CertsListComponent from './components/certs/CertsListComponent.vue';
import FileUploadComponent from './components/files/FileUploadComponent.vue';
import CertDownloadComponent from './components/certs/CertDownloadComponent.vue';
import CertVerificationComponent from './components/certs/CertVerificationComponent.vue';
import FileManagerComponent from './components/files/FileManagerComponent.vue';
import DropzoneComponent from './components/files/DropzoneComponent.vue';

const emitter = mitt();

// template JS
//import jQuery from 'jquery';
import './vendors/jquery-3.6.0.min.js';
window.$ = jQuery;
import './vendors/swiper-bundle.min.js';
//import './vendors/jquery.magnific-popup.min.js';
//import './vendors/aos.js';
import './app-template.js';

//styles
import 'dropzone-vue/dist/dropzone-vue.common.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-skeletor/dist/vue-skeletor.css';

// validations
import { Field, Form, ErrorMessage, defineRule, configure } from 'vee-validate';
import { required, email, min, max, between, confirmed, max_value, min_value, numeric } from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';
import cs from '@vee-validate/i18n/dist/locale/cs.json';

// define validation rules
configure({
    generateMessage: localize({
        cs
    })
});
setLocale('cs');
defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('max', max);
defineRule('min_value', min_value);
defineRule('max_value', max_value);
defineRule('between', between);
defineRule('confirmed', confirmed);
defineRule('numeric', numeric);

/* createApp(App)
    .use(router)
    .component('CertsListComponent', CertsListComponent)
    .component('FileUploadComponent', FileUploadComponent)
    .mount("#app") */

const app = createApp(App);

const i18n = createI18n({
    locale: 'cs',
    fallbackLocale: 'en',
    messages: {
        cs: languages.cs,
        en: languages.en,
    }
})

//Sentry
import * as Sentry from "@sentry/vue";

Sentry.init({
    app,
    dsn: "https://4e8f1d710a14cb7de64f95982baead7c@o4504570118275072.ingest.sentry.io/4506024811888640",
    org: 'inove-dev',
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\/waipal\.com\/api/],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router);
app.use(i18n);
app.use(VueSweetalert2);
app.component('dropzone', DropZone);
app.component(Skeletor.name, Skeletor);
app.component('CertsListComponent', CertsListComponent);
app.component('CertDownloadComponent', CertDownloadComponent);
app.component('CertVerificationComponent', CertVerificationComponent);
app.component('FileUploadComponent', FileUploadComponent);
app.component('FileManagerComponent', FileManagerComponent);
app.component('DropzoneComponent', DropzoneComponent);
app.component('Field', Field);
app.component('Form', Form);
app.component('ErrorMessage', ErrorMessage);
app.config.globalProperties.emitter = emitter;

app.mount("#app");



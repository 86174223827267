<template>
  <div class="mb-3">
    <dropzone
      :options="dropzoneOptions"
      :headers="dropzoneOptionsHeaders"
      :url="upload_url"
      method="POST"
      @uploaded="fileUploaded"
      @sending="fileSending"
    ></dropzone>
  </div>
</template>

<script>
import http from '@/http';

export default {
  props: ['selectedFolderUuid'],

  mounted() {
    let el = document.querySelectorAll('.dropzone .dropzone__message');
    el[0].innerText =
      this.$t('file.dropzoneText')
  },

  data() {
    return {
      dropzoneOptions: {
        maxFiles: 'Number(10000000000)',
        uploadOnDrop: 'true',
        multipleUpload: 'true',
        parallelUpload: '3',
        method: 'POST',
        params: {},
      },
      dropzoneOptionsHeaders: {
        'X-XSRF-TOKEN': document.head.querySelector('[name="csrf-token"]').content,
        'X-CSRF-TOKEN': document.head.querySelector('[name="csrf-token"]').content,
      },
    };
  },

  computed: {
    upload_url() {
      return import.meta.env.VITE_API_URL + '/files/upload';
    },
  },

  methods: {
    fileUploaded() {},
    fileSending(file, xhr, formData) {
      self = this;
      formData.append('folderUuid', this.selectedFolderUuid ? this.selectedFolderUuid : null);
      function callback(response) {
        self.emitter.emit('fileUploaded', response);
      }
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          callback(xhr.response);
        }
      };
    },
  },
};
</script>

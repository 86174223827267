<template>
    <div class="card">
        <div class="card-header">{{ $t('cert.myCertificates') }}</div>
        <div class="card-body">
            <Skeletor v-if="loading"/>
            <div v-if="!loading">
                <div v-if="!certsList.length">{{ $t('general.noRecord') }}</div>
                <div v-else>
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">{{ $t('cert.certName') }}</th>
                            <th scope="col">{{ $t('cert.certCreatedDate') }}</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="cert in certsList" class="align-middle">
                            <td scope="col">{{ cert.name }}</td>
                            <td scope="col">{{ formatDate(cert.created_at) }}</td>
                            <td scope="col" class="text-end">
                                <router-link
                                    class="btn btn-primary btn-sm"
                                    :to="{ name: 'CertDetail', params: { uuid: cert.uuid } }"
                                    title="Detail certifikátu"
                                ><i class="fa fa-eye"></i
                                ></router-link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import http from '@/http';

export default {
    mounted() {
        this.getCertsList();
        let self = this;
        this.emitter.on('certAdded', (newCert) => {
            self.certsList.push(newCert);
        });
    },

    data() {
        return {
            certsList: [],
            loading: true,
        };
    },

    methods: {
        getCertsList() {
            let self = this;
            http.get('/certs/list').then((response) => {
                self.certsList = response.data.userCerts;
                self.loading = false;
            });
        },

        formatDate(date) {
            let d = new Date(date);
            return (
                ('00' + d.getDate()).slice(-2) +
                '. ' +
                ('00' + (d.getMonth() + 1)).slice(-2) +
                '. ' +
                d.getFullYear() +
                ' ' +
                ('00' + d.getHours()).slice(-2) +
                ':' +
                ('00' + d.getMinutes()).slice(-2) +
                ':' +
                ('00' + d.getSeconds()).slice(-2)
            );
        },
        /*  toggleModal() {
              const body = document.querySelector('body');
              this.modalActive = !this.modalActive;
              this.modalActive
                ? body.classList.add('modal-open')
                : body.classList.remove('modal-open');
              setTimeout(() => (this.modalShow = !this.modalShow), 10);
            }, */
    },
};
</script>

<template>
  <div class="row mt-4">
    <div class="col-12">
      <CertVerificationComponent></CertVerificationComponent>
    </div>
  </div>
  <div v-if="verificationMessage" class="row mt-4">
    <div class="col-12">
      <div v-if="verificationResult != 'false'" class="alert alert-success" role="alert">
        {{ verificationMessage }}
      </div>
      <div v-else class="alert alert-danger" role="alert">
        {{ verificationMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/http';
import CertVerificationComponent from '../components/certs/CertVerificationComponent.vue';
import FileUploadComponentComponent from '../components/files/FileUploadComponent.vue';

export default {
  mounted() {
    let self = this;
    this.emitter.on('certChecked', (certVerificationResult) => {
      self.verificationResult = certVerificationResult.verificationStatus.verified;
      self.verificationMessage = certVerificationResult.verificationStatus.message;
    });
  },
  data() {
    return {
      verificationResult: false,
      verificationMessage: null,
    };
  },
  methods: {
    formatDate(date) {
      let d = new Date(date);
      return (
        ('00' + d.getDate()).slice(-2) +
        '. ' +
        ('00' + (d.getMonth() + 1)).slice(-2) +
        '. ' +
        d.getFullYear() +
        ' ' +
        ('00' + d.getHours()).slice(-2) +
        ':' +
        ('00' + d.getMinutes()).slice(-2) +
        ':' +
        ('00' + d.getSeconds()).slice(-2)
      );
    },
  },
};
</script>

<template>
    <div class="card">
        <div class="card-header d-flex">
            {{ $t('file.myFiles') }}
            <div class="ms-auto">
                <button class="btn btn-primary" @click="toggleModal()">
                    {{ $t('file.createFolder') }}
                </button>
            </div>
        </div>
        <div class="card-body">
            <dropzone-component :selectedFolderUuid="selectedUuid"></dropzone-component>
            <Skeletor v-if="loading"/>
            <div v-if="!loading">
                <div v-if="!files.length">{{ $t('general.noRecord') }}</div>
                <div v-else>
                    <table class="table">
                        <thead v-if="parent && parent.name">
                        <tr class="align-middle w-100" style="border-bottom: 2px">
                            <td colspan="2">
                                <strong
                                >
                                    <div @click="getFoldersList()" style="display: inline">
                                        <span class="folder-link">...</span> /
                                    </div>
                                    <div v-if="parent.folders_path" style="display: inline">
                                        <div
                                            v-for="ancestor in parent.folders_path.reverse()"
                                            @click="getFoldersList(ancestor.uuid)"
                                            style="display: inline"
                                        >
                                            <span class="folder-link">{{ ancestor.name }}</span> /
                                        </div>
                                    </div>
                                    {{ parent.name }}</strong
                                >
                            </td>
                            <td class="text-end">
                                <button class="btn btn-primary" @click="getFoldersList(parent.parent_uuid)">
                                    <i class="bi-box-arrow-left"></i>
                                </button>
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="file in files"
                            class="align-middle file-manager-row"
                            @click="clickFunction(file)"
                        >
                            <td style="width: 10%">
                                <div class="" v-if="file.type == 'FOLDER'">
                <span class="folder-icon">
                  <i class="bi bi-folder2"></i>
                </span>
                                </div>
                                <div v-else class="ms-1">
                <span :class="'file-icon'">
                  <span class="file-icon-icon"><i class="bi bi-file-earmark"></i></span>
                  <span class="file-icon-extension">{{ file.extension }}</span>
                </span>
                                </div>
                            </td>
                            <td style="width: 80%">{{ file.name }}</td>
                            <td style="width: 10%" class="text-end">
                                <button class="btn btn-primary">
                                    <i class="bi-eye"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div v-if="files && loadMoreAvailable">
                        <button
                            class="btn btn-primary w-100"
                            v-if="parent"
                            v-on:click="loadMore(parent?.id)"
                        >
                            {{ $t('file.loadMore') }}
                        </button>
                        <button class="btn btn-primary w-100" v-else v-on:click="loadMore()">
                            {{ $t('file.loadMore') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Create Folder Modal -->
        <div
            class="modal bottom fade"
            :class="{ show: modalShow, 'd-block': modalActive }"
            style="overflow-y: scroll"
            id="ModalCreateFolder"
            tabindex="-1"
            role="dialog"
        >
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content border-0">
                    <div class="modal-body p-3 d-flex align-items-center bg-none">
                        <div class="card shadow-none rounded-0 w-100 p-2 pt-3 border-0">
                            <div class="card-body rounded-0 text-left p-3">
                                <div class="row">
                                    <div class="col-12 d-flex justify-content-between">
                                        <div>
                                            <h2 class="fw-700 display1-size display2-md-size mb-4">
                                                {{ $t('file.newFolder') }}
                                            </h2>
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                class="btn btn-primary"
                                                @click="toggleModal"
                                                aria-label="Close"
                                            >
                                                <i class="fa-solid fa-x"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <Form
                                    @submit="handleCreateFolderFormSend"
                                    :validation-schema="formValidationSchema"
                                    ref="createFolderForm"
                                >
                                    <div class="card-body p-0 mt-3 position-relative">
                                        <label class="mont-font fw-600 font-xssss">{{
                                                $t('file.name')
                                            }}</label>
                                        <Field
                                            type="text"
                                            class="form-control mb-3"
                                            name="name"
                                            id="name"
                                            label="Název"
                                            placeholder="Název"
                                            v-model="name"
                                        />
                                        <ErrorMessage class="invalid-feedback" name="name"/>
                                    </div>
                                    <div class="col-lg-12 d-flex justify-content-end mt-3">
                                        <button type="submit" class="btn btn-primary">
                                            <span>{{ $t('file.create') }}</span>
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="modalActive" class="modal-backdrop fade show"></div>
    </div>
</template>

<script>
import http from '@/http';

export default {
    mounted() {
        this.getFoldersList();
        let self = this;
        this.emitter.on('fileUploaded', (newFile) => {
            newFile = JSON.parse(newFile);
            self.getFileDetail(newFile.uuid);
        });
        this.emitter.on('certAdded', (newCert) => {
            self.getFileDetail(newCert.file.uuid);
        });
    },

    data() {
        return {
            files: [],
            parent: null,
            name: '',
            selectedUuid: null,
            modalActive: false,
            modalShow: false,

            formValidationSchema: {
                /*  name: 'required|min:5',
                email: 'required|email', */
            },
            loading: true,
            page: 1,
            perPage: 10,
            loadMoreAvailable: false,
        };
    },

    methods: {
        getFoldersList(uuid, appendToExistingFiles) {
            let self = this;
            let path = '/files/folders';
            if (uuid) {
                path = path + '/' + uuid;
            }

            let queryString = '?perPage=' + self.perPage + '&page=' + self.page.toString();

            let url = path + queryString;

            http.get(url).then((response) => {
                /*   self.folders = response.data.folders; */
                self.parent = response.data.folder;
                self.selectedUuid = uuid;

                if (appendToExistingFiles) {
                    self.files = self.files.concat(response.data.files);
                } else {
                    self.files = response.data.files;
                }

                if (response.data.files?.length == self.perPage) {
                    self.loadMoreAvailable = true;
                } else {
                    self.loadMoreAvailable = false;
                }

                self.loading = false;
            });
        },

        loadMore(parentId) {
            this.page++;
            this.getFoldersList(parentId, true);
        },

        createFolder() {
            let body = {
                name: this.name ? this.name : this.$t('file.newFolder'),
                uuid: this.selectedUuid ? this.selectedUuid : null,
            };
            let self = this;

            http.post('/files/folders/create', body).then((response) => {
                self.files.push(response.data.file);
                self.files = self.files.sort((a, b) => {
                    const typeA = a.type !== null && a.type !== undefined ? a.type : '';
                    const typeB = b.type !== null && b.type !== undefined ? b.type : '';

                    const typeComparison = typeB.localeCompare(typeA);

                    if (typeComparison === 0) {
                        const nameA = a.name !== null && a.name !== undefined ? a.name : '';
                        const nameB = b.name !== null && b.name !== undefined ? b.name : '';
                        return nameA.localeCompare(nameB);
                    }

                    return typeComparison;
                });
                self.toggleModal();
            });
        },

        getFileDetail(uuid) {
            let self = this;
            http.get('/files/' + uuid + '/detail').then((response) => {
                self.files.push(response.data.file);
            });
        },

        clickFunction(file) {
            if (file.type === 'FOLDER') {
                this.page = 1;
                this.loadMoreAvailable = true;
                this.getFoldersList(file.uuid);
            } else {
                this.$router.push({name: 'FileDetail', params: {uuid: file.uuid}});
            }
        },

        handleCreateFolderFormSend() {
            this.createFolder();
            return;
        },

        toggleModal() {
            const body = document.querySelector('body');
            this.modalActive = !this.modalActive;
            this.modalActive
                ? body.classList.add('modal-open')
                : body.classList.remove('modal-open');
            setTimeout(() => (this.modalShow = !this.modalShow), 10);
            if (!this.modalActive) {
                this.$refs.createFolderForm.resetForm();
            }
        },
    },
};
</script>

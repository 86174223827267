<template>
  <div class="container mt-3">
    <div class="row">
      <h2>{{ $t('cert.certView') }} / {{ cert.name }}</h2>
    </div>
    <div class="row d-flex justify-content-between">
      <div class="col-auto">
        <router-link
          class="arrow-back"
          :to="{ name: 'Dashboard' }"
          title="Zpět na nástěnku"
        >
          <i class="bi bi-arrow-left"></i>{{ $t('general.backToDashboard') }}</router-link
        >
      </div>
      <div class="col-auto">
        <a
          id="pdfOpenButton"
          :href="'data:application/pdf;base64,' + cert.pdf_base64"
          target="_blank"
          class="button-blue"
        >
          <i class="bi bi-window me-2"></i> Zobrazit v novém okně</a
        >
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <vue-pdf-embed v-if="pdfSrc" ref="pdfRef" :source="pdfSrc" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/http';
import VuePdfEmbed from 'vue-pdf-embed';

export default {
  components: {
    VuePdfEmbed,
  },

  mounted() {
    this.getCertDetail();
    document.getElementById('pdfOpenButton').addEventListener('click', function () {
      var pdf = document.getElementById('pdfOpenButton').getAttribute('href');

      let pdfWindow = window.open(pdf);
      setTimeout(function () {
        pdfWindow.document.write(
          "<iframe width='100%' height='100%' src='" + encodeURI(pdf) + "'></iframe>"
        );
      }, 0);
    });
  },

  data() {
    return {
      cert: [],
      pdfSrc: null,
    };
  },

  methods: {
    getCertDetail() {
      let self = this;
      http.get('/certs/' + self.$route.params.uuid + '/detail').then((response) => {
        self.cert = response.data.cert;
        this.getPdfSrc();
      });
    },

    getPdfSrc() {
      this.pdfSrc = 'data:application/pdf;base64,' + this.cert.pdf_base64;
    },
  },
};
</script>

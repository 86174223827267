<template>
  <div class="skelet">
    <div id="uvod" class="columns align-items-center" style="padding-top: Opx !important">
      <div class="column">
        <h1>{{ $t('cert.fileCertVerification') }}</h1>
        <p>
          Naše služba využívá moderní <strong>blockchain technologii</strong> k rychlému a
          spolehlivému ověření a registraci vlastnictví důležitých dokumentů, autorských
          práv, licencí apod.
        </p>
        <p>
          Nemějte strach, že bude vaše dílo ukradeno nebo bude zkopírováno. Získejte
          digitální certifikát s naším digitálním ověřováním.
        </p>
      </div>
      <!-- column -->
      <div class="column align-middle">
        <Form :validation-schema="formValidationSchema">
          <div class="row mb-3">
            <div class="col">
              <label for="uuid" class="form-label">{{ $t('cert.certID') }}</label>
              <Field
                type="text"
                class="form-control"
                v-model="uuid"
                id="uuid"
                name="uuid"
                placeholder="ID certifikátu"
              />
              <ErrorMessage class="invalid-feedback" name="uuid" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <!--  <Field name="file" v-slot="{ onFileChanged }"> -->
              <input
                type="file"
                name="file"
                class="form-control"
                placeholder="Soubory k nahrání"
                @change="onFileChanged($event)"
                ref="files"
                required
              /><!-- </Field>
              <ErrorMessage class="invalid-feedback" name="file" /> -->
            </div>
          </div>
          <div class="text-end">
            <button type="submit"  @click="handleFormSend" class="btn btn-primary">{{ $t('cert.verify') }}</button>
          </div>
        </Form>
      </div>
      <!-- column -->
    </div>
    <!-- uvod, columns -->
  </div>
</template>

<script>
import http from '@/http';
// validations
import { Field, Form, ErrorMessage, defineRule, configure } from 'vee-validate';
import {
  required,
  email,
  min,
  max,
  between,
  confirmed,
  max_value,
  min_value,
  numeric,
} from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';
import cs from '@vee-validate/i18n/dist/locale/cs.json';

export default {
  mounted() {},
  components: { Field, ErrorMessage },
  data() {
    return {
      file: null,
      uuid: this.$route.query.uuid ? this.$route.query.uuid : null,
      formValidationSchema: {
        uuid: 'required|min:10',
        file: 'required',
      },
    };
  },

  methods: {
    verifyCert() {
      let body = {
        file: this.file,
      };
      let self = this;

      http
        .post('/certs/' + this.uuid + '/verification', body, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          self.emitter.emit('certChecked', response.data);
        });
    },

    onFileChanged(event) {
      this.file = this.$refs.files.files[0];
    },

    handleFormSend() {
      this.verifyCert();
      return;
    },
  },
};
</script>

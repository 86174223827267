import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from './pages/Dashboard.vue';
import CertDetail from './pages/CertDetail.vue';
import CertVerification from './pages/CertVerification.vue';
import CertPdfDetail from './pages/CertPdfDetail.vue';
import ClientProfile from './pages/ClientProfile.vue';
import FileDetail from './pages/FileDetail.vue';

const routes = [
    {
        path: '/',
        component: () => import('./App.vue'),
        //redirect: '/dashboard',
        children: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                component: Dashboard,
                meta: {
                    auth: true
                }
            },
            {
                path: '/certs/:uuid/detail',
                name: 'CertDetail',
                component: CertDetail,
                meta: {
                    auth: true
                }
            },
            {
                path: '/certs/:uuid/view',
                name: 'CertPdfDetail',
                component: CertPdfDetail,
                meta: {
                    auth: true
                }
            },
            {
                path: '/files/:uuid/detail',
                name: 'FileDetail',
                component: FileDetail,
                meta: {
                    auth: true
                }
            },
            {
                path: '/profile',
                name: 'ClientProfile',
                component: ClientProfile,
                meta: {
                    auth: true
                }
            },
        ]
    },
    {
        path: '/verification/:hash?',
        component: CertVerification
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        window.scrollTo(0, 0);
    },
})
export default router

<template>
  <div class="container mt-3">
    <div class="row">
      <h2>{{ $t('client.myProfile') }}</h2>
    </div>
    <div class="row">
      <router-link
          class="arrow-back"
          :to="{ name: 'Dashboard' }"
          title="Zpět na nástěnku"
      >
        <i class="bi bi-arrow-left"></i>{{ $t('general.backToDashboard') }}
      </router-link
      >
    </div>
    <div class="row mt-4">
      <div class="col-12 col-lg-6 mb-3">
        <div class="card mb-3">
          <div class="card-header d-flex">
            {{ $t('client.myInformation') }}
            <div class="ms-auto">
              <button class="btn btn-primary" @click="toggleModal">
                {{ $t('general.edit') }}
              </button>
            </div>
          </div>
          <div class="card-body">
            <Skeletor v-if="isLoading"/>
            <div v-if="!isLoading && !isName" class="row mt-3 mx-2">
              <div class="alert alert-danger col-12 p-2">{{ $t('client.notFilledName') }}</div>
            </div>
            <div v-if="!isLoading">
              <div class="row">
                <div class="col-6">{{ $t('client.name') }}:</div>
                <div class="col-6">{{ client.name }}</div>
              </div>
              <div class="row">
                <div class="col-6">{{ $t('client.email') }}:</div>
                <div class="col-6">{{ client.email }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header d-flex">
            {{ $t('client.passwordChange') }}
          </div>
          <div class="card-body">
            <div class="d-flex justify-content-end">
              <button class="btn btn-primary" @click="togglePasswordModal()">
                {{ $t('general.edit') }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="card mb-3">
          <div class="card-header d-flex">
            {{ $t('client.myAddress') }}
            <div class="ms-auto">
              <button class="btn btn-primary" @click="toggleAddressModal">
                {{ $t('general.edit') }}
              </button>
            </div>
          </div>
          <div class="card-body">
            <Skeletor v-if="isLoading"/>
            <div v-if="!isLoading && !isAddress" class="row mt-3 mx-2">
              <div class="alert alert-danger col-12 p-2">{{ $t('client.notFilledAddress') }}</div>
            </div>
            <div v-if="!isLoading">
              <div class="row">
                <div class="col-6">{{ $t('client.line1') }}:</div>
                <div class="col-6">{{ client.address?.line_1 }}</div>
              </div>
              <div class="row">
                <div class="col-6">{{ $t('client.line2') }}:</div>
                <div class="col-6">{{ client.address?.line_2 }}</div>
              </div>
              <div class="row">
                <div class="col-6">{{ $t('client.city') }}:</div>
                <div class="col-6">{{ client.address?.city }}</div>
              </div>
              <div class="row">
                <div class="col-6">{{ $t('client.postalCode') }}:</div>
                <div class="col-6">{{ client.address?.postal_code }}</div>
              </div>
              <div class="row">
                <div class="col-6">{{ $t('client.countryCode') }}:</div>
                <div class="col-6">{{ client.address?.country_code }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header d-flex">
            {{ $t('client.verification') }}
            <div class="ms-auto">
              <button class="btn btn-primary" @click="toggleVerificationModal">
                {{ $t('general.edit') }}
              </button>
            </div>
          </div>
          <div class="card-body">
            <Skeletor v-if="isLoading"/>
            <div v-if="!isLoading && !isAllVerificationData" class="row mt-3 mx-2">
              <div class="alert alert-danger col-12 p-2">{{ $t('client.notFilledAllData') }}</div>
            </div>
            <div v-if="!isLoading">
              <div class="row">
                <div class="col-6">{{ $t('client.documentType') }}:</div>
                <div class="col-6">
                  {{
                    client.verification?.document_type ? $t('client.' + client.verification?.document_type) : '---'
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col-6">{{ $t('client.documentNumber') }}:</div>
                <div class="col-6">{{
                    client.verification?.document_number ? client.verification?.document_number : '---'
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col-6">{{ $t('client.expirationDate') }}:</div>
                <div class="col-6">
                  {{
                    client.verification?.expiration_date ? formatDate(client.verification?.expiration_date) : '---'
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Client Profile Modal -->
    <div
        class="modal bottom fade"
        :class="{ show: modalShow, 'd-block': modalActive }"
        style="overflow-y: scroll"
        id="ModalEditClient"
        tabindex="-1"
        role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content border-0">
          <div class="modal-body p-3 d-flex align-items-center bg-none">
            <div class="card shadow-none rounded-0 w-100 p-2 pt-3 border-0">
              <div class="card-body rounded-0 text-left p-3">
                <div class="row">
                  <div class="col-12 d-flex justify-content-between">
                    <div>
                      <h2 class="fw-700 display1-size display2-md-size mb-4">
                        {{ $t('client.editPersonalInformation') }}
                      </h2>
                    </div>
                    <div>
                      <button
                          type="button"
                          class="btn btn-primary"
                          @click="toggleModal"
                          aria-label="Close"
                      >
                        <i class="fa-solid fa-x"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <Form
                    @submit="handleClientInformationFormSend"
                    :validation-schema="formValidationSchema"
                    ref="clientProfileEditForm"
                >
                  <div class="card-body p-0 mt-3 position-relative">
                    <label class="mont-font fw-600 font-xssss">{{
                        $t('client.name')
                      }}</label>
                    <Field
                        type="text"
                        class="form-control mb-3"
                        name="name"
                        id="name"
                        label="Jméno"
                        placeholder="Jméno"
                        v-model="clientForModal.name"
                    />
                    <ErrorMessage class="invalid-feedback" name="name"/>
                    <label class="mont-font fw-600 font-xssss">{{
                        $t('client.email')
                      }}</label>
                    <Field
                        type="text"
                        class="form-control"
                        name="email"
                        id="email"
                        label="E-mail"
                        placeholder="E-mail"
                        v-model="clientForModal.email"
                    />
                    <ErrorMessage class="invalid-feedback" name="email"/>
                  </div>
                  <div class="col-lg-12 d-flex justify-content-end mt-3">
                    <button type="submit" class="btn btn-primary">
                      <span>{{ $t('general.edit') }}</span>
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Client Address Modal -->
    <div
        class="modal bottom fade"
        :class="{ show: modalAddressShow, 'd-block': modalAddressActive }"
        style="overflow-y: scroll"
        id="ModalEditClientAddress"
        tabindex="-1"
        role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content border-0">
          <div class="modal-body p-3 d-flex align-items-center bg-none">
            <div class="card shadow-none rounded-0 w-100 p-2 pt-3 border-0">
              <div class="card-body rounded-0 text-left p-3">
                <div class="row">
                  <div class="col-12 d-flex justify-content-between">
                    <div>
                      <h2 class="fw-700 display1-size display2-md-size mb-4">
                        {{ $t('client.editID') }}
                      </h2>
                    </div>
                    <div>
                      <button
                          type="button"
                          class="btn btn-primary"
                          @click="toggleAddressModal"
                          aria-label="Close"
                      >
                        <i class="fa-solid fa-x"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <Form
                    @submit="handleClientAddressFormSend"
                    :validation-schema="formAddressValidationSchema"
                    ref="clientAddressEditForm"
                >
                  <div class="card-body p-0 mt-3 position-relative">
                    <label class="mont-font fw-600 font-xssss">{{
                        $t('client.line1')
                      }}</label>
                    <Field
                        type="text"
                        class="form-control mb-3"
                        name="line_1"
                        id="line_1"
                        label="Řádek 1"
                        placeholder="Řádek 1"
                        v-model="clientForModal.address.line_1"
                    />
                    <ErrorMessage class="invalid-feedback" name="line_1"/>
                    <label class="mont-font fw-600 font-xssss">{{
                        $t('client.line2')
                      }}</label>
                    <Field
                        type="text"
                        class="form-control mb-3"
                        name="line_2"
                        id="line_2"
                        label="Řádek 2"
                        placeholder="Řádek 2"
                        v-model="clientForModal.address.line_2"
                    />
                    <ErrorMessage class="invalid-feedback" name="line_2"/>
                    <label class="mont-font fw-600 font-xssss">{{
                        $t('client.city')
                      }}</label>
                    <Field
                        type="text"
                        class="form-control mb-3"
                        name="city"
                        id="city"
                        label="Město"
                        placeholder="Město"
                        v-model="clientForModal.address.city"
                    />
                    <ErrorMessage class="invalid-feedback" name="city"/>
                    <label class="mont-font fw-600 font-xssss">{{
                        $t('client.postalCode')
                      }}</label>
                    <Field
                        type="text"
                        class="form-control mb-3"
                        name="postal_code"
                        id="postal_code"
                        label="PSČ"
                        placeholder="PSČ"
                        v-model="clientForModal.address.postal_code"
                    />
                    <ErrorMessage class="invalid-feedback" name="postal_code"/>
                  </div>
                  <div>
                    <label class="mont-font fw-600 font-xssss">{{
                        $t('client.countryCode')
                      }}</label>
                    <Field
                        as="select"
                        class="form-control mb-3"
                        name="country_code"
                        id="country_code"
                        label="Kód země"
                        placeholder="Kód země"
                        v-model="clientForModal.address.country_code">
                      <option value="" disabled :selected="!clientForModal.address || !clientForModal.address.country_code">
                        {{ $t('client.chooseCountry') }}
                      </option>
                      <option
                          v-for="country in countries"
                          :label="country.name"
                          :value="country.country_code"
                      ></option>
                    </Field>
                  </div>
                  <div class="col-lg-12 d-flex justify-content-end mt-3">
                    <button type="submit" class="btn btn-primary">
                      <span>{{ $t('general.edit') }}</span>
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Client Verification Modal -->
    <div
        class="modal bottom fade"
        :class="{ show: modalVerificationShow, 'd-block': modalVerificationActive }"
        style="overflow-y: scroll"
        id="ModalEditClientVerification"
        tabindex="-1"
        role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content border-0">
          <div class="modal-body p-3 d-flex align-items-center bg-none">
            <div class="card shadow-none rounded-0 w-100 p-2 pt-3 border-0">
              <div class="card-body rounded-0 text-left p-3">
                <div class="row">
                  <div class="col-12 d-flex justify-content-between">
                    <div>
                      <h2 class="fw-700 display1-size display2-md-size mb-4">
                        {{ $t('client.editID') }}
                      </h2>
                    </div>
                    <div>
                      <button
                          type="button"
                          class="btn btn-primary"
                          @click="toggleVerificationModal"
                          aria-label="Close">
                        <i class="fa-solid fa-x"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <Form
                    @submit="handleClientVerificationFormSend"
                    :validation-schema="formVerificationValidationSchema"
                    ref="clientProfileEditForm"
                >
                  <div class="card-body p-0 mt-3 position-relative">
                    <label class="mont-font fw-600 font-xssss">{{
                        $t('client.documentType')
                      }}</label>
                    <Field
                        as="select"
                        class="form-control mb-3"
                        name="document_type"
                        id="document_type"
                        label="Typ dokladu"
                        placeholder="Typ dokladu"
                        v-slot="{ value }"
                        v-model="clientForModal.verification.document_type"
                    >
                      <option value="" disabled>
                        {{ $t('client.chooseDocumentType') }}
                      </option>
                      <option
                          v-for="type in documentTypeOptions"
                          :label="type.name"
                          :value="type.value"
                      ></option>
                    </Field>
                    <ErrorMessage class="invalid-feedback" name="document_type"/>
                    <label class="mont-font fw-600 font-xssss">{{
                        $t('client.documentNumber')
                      }}</label>
                    <Field
                        type="text"
                        class="form-control mb-3"
                        name="document_number"
                        id="document_number"
                        label="Číslo dokladu"
                        placeholder="Číslo dokladu"
                        v-model="clientForModal.verification.document_number"
                    />
                    <ErrorMessage class="invalid-feedback" name="document_number"/>
                    <label class="mont-font fw-600 font-xssss">{{
                        $t('client.expirationDate')
                      }}</label>
                    <input
                        type="date"
                        class="form-control mb-3"
                        id="expiration_date"
                        name="expiration_date"
                        v-model="clientForModal.verification.expiration_date"
                    />
                    <ErrorMessage class="invalid-feedback" name="expiration_date"/>
                  </div>
                  <div class="col-lg-12 d-flex justify-content-end mt-3">
                    <button type="submit" class="btn btn-primary">
                      <span>{{ $t('general.edit') }}</span>
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Client Password Modal -->
    <div
        class="modal bottom fade"
        :class="{ show: modalPasswordShow, 'd-block': modalPasswordActive }"
        style="overflow-y: scroll"
        id="ModalResetPassword"
        tabindex="-1"
        role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content border-0">
          <div class="modal-body p-3 d-flex align-items-center bg-none">
            <div class="card shadow-none rounded-0 w-100 p-2 pt-3 border-0">
              <div class="card-body rounded-0 text-left p-3">
                <div class="row">
                  <div class="col-12 d-flex justify-content-between">
                    <div>
                      <h2 class="fw-700 display1-size display2-md-size mb-4">
                        {{ $t('client.passwordChange') }}
                      </h2>
                    </div>
                    <div>
                      <button
                          type="button"
                          class="btn btn-primary"
                          @click="togglePasswordModal"
                          aria-label="Close">
                        <i class="fa-solid fa-x"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <Form
                    @submit="handleClientPasswordChangeFormSend"
                    :validation-schema="formPasswordChangeValidationSchema"
                    ref="clientPasswordChangeForm"
                >
                  <div class="row">
                    <div class="col-lg-12 mb-3">
                      <div class="form-group">
                        <label class="mont-font fw-600 font-xssss">{{
                            $t('client.newPassword')
                          }}</label>
                        <Field
                            type="password"
                            name="password"
                            class="form-control"
                            v-model="password"
                            id="password"
                            :label="$t('client.password')"
                        />
                        <ErrorMessage class="invalid-feedback" name="password"/>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12 mb-3">
                      <div class="form-group">
                        <label class="mont-font fw-600 font-xssss">{{
                            $t('client.newPasswordConfirmation')
                          }}</label>
                        <Field
                            type="password"
                            name="password_confirmation"
                            class="form-control"
                            id="password_confirmation"
                            :label="$t('client.passwordConfirmation')"
                            v-model="password_confirmation"
                        />
                        <ErrorMessage
                            class="invalid-feedback"
                            name="password_confirmation"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 d-flex justify-content-end mt-3">
                    <button type="submit" class="btn btn-primary">
                      <span>{{ $t('general.edit') }}</span>
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="modalActive" class="modal-backdrop fade show"></div>
    <div v-if="modalAddressActive" class="modal-backdrop fade show"></div>
    <div v-if="modalVerificationActive" class="modal-backdrop fade show"></div>
    <div v-if="modalPasswordActive" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import http from '@/http';

const EMPTY_CLIENT = {
  name: '',
  email: '',
  address: {
    line_1: '',
    line_2: '',
    city: '',
    country_code: '',
    postal_code: '',
  },
  verification: {
    document_type: '',
    document_number: '',
    expiration_date: '',
  },
};

export default {
  mounted() {
    this.getAuthClient();
    this.getCountries();
    let self = this;
    self.clientForModal = {...EMPTY_CLIENT};
    if (self.clientForModal.address == null) {
      self.clientForModal.address = {...EMPTY_CLIENT.address};
    }
  },

  data() {
    return {
      client: {
        name: '',
        email: '',
        address: {
          line_1: '',
          line_2: '',
          city: '',
          country_code: '',
          postal_code: '',
        },
        verification: {
          document_type: '',
          document_number: '',
          expiration_date: '',
        },
      },
      password: '',
      password_confirmation: '',
      countries: [],
      modalActive: false,
      modalShow: false,
      modalAddressActive: false,
      modalAddressShow: false,
      modalVerificationActive: false,
      modalVerificationShow: false,
      modalPasswordActive: false,
      modalPasswordShow: false,
      clientForModal: {
        address: {
          line_1: '',
          line_2: '',
          city: '',
          country_code: '',
          postal_code: '',
        },
        verification: {
          document_type: '',
          document_number: '',
          expiration_date: '',
        },
      },
      documentTypeOptions: [
        {name: this.$t('client.IDENTITY_CARD'), value: 'IDENTITY_CARD'},
        {name: this.$t('client.DRIVER_LICENCE'), value: 'DRIVER_LICENCE'},
        {name: this.$t('client.PASSPORT'), value: 'PASSPORT'},
      ],
      formValidationSchema: {
        name: 'required|min:5',
        email: 'required|email',
      },
      formAddressValidationSchema: {
        line_1: 'required|min:3',
        city: 'required',
        country_code: '',
        postal_code: 'required',
      },
      formVerificationValidationSchema: {
        document_type: '',
        document_number: '',
        expiration_date: '',
      },
      formPasswordChangeValidationSchema: {
        password: 'required|min:8',
        password_confirmation: 'required|min:8|confirmed:@password',
      },
      isLoading: true
    };
  },

  computed: {
    isName() {
      return this.client.name && this.client.name.length !== 0;
    },

    isAddress() {
      return this.client.address.line_1.length !== 0 && this.client.address.city.length !== 0 && this.client.address.postal_code.length !== 0;
    },

    isAllVerificationData() {
      return this.client.verification && this.client.verification.document_type?.length !== 0 && this.client.verification.document_number?.length !== 0 && this.client.verification.expiration_date?.length !== 0;
    },
  },

  methods: {
    getAuthClient() {
      let self = this;
      self.isLoading = true;
      http.get('/get-auth-client').then((response) => {
        self.client = response.data.client;
        if (!response.data.client.address) {
          self.client.address = {...EMPTY_CLIENT.address};
        }
        if (!response.data.client.verification) {
          self.client.verification = {...EMPTY_CLIENT.verification};
        }
        self.clientForModal = self.client;
        self.isLoading = false;
      });
    },

    getCountries() {
      let self = this;
      http.get('/countries').then((response) => {
        self.countries = response.data.countries;
      });
    },

    editClientInformation() {
      let self = this;
      let body = {
        name: this.clientForModal.name,
        email: this.clientForModal.email,
      };
      http.post('/profile/' + self.client.uuid + '/edit', body).then((response) => {
        self.client = response.data.client;
        if (!response.data.client.address) {
          self.client.address = {...EMPTY_CLIENT.address};
        }
        if (!response.data.client.verification) {
          self.client.verification = {...EMPTY_CLIENT.verification};
        }
        self.toggleModal();
      });
    },

    editClientAddress() {
      let self = this;
      let body = {
        line_1: this.clientForModal.address.line_1,
        line_2: this.clientForModal.address.line_2,
        city: this.clientForModal.address.city,
        country_code: this.clientForModal.address.country_code
            ? this.clientForModal.address.country_code
            : 'CZ',
        postal_code: this.clientForModal.address.postal_code,
      };
      http.post('/address/' + self.client.uuid + '/edit', body).then((response) => {
        self.client = response.data.client;
        self.toggleAddressModal();
      });
    },

    editClientVerification() {
      let self = this;
      let body = {
        document_type: this.clientForModal.verification.document_type
            ? this.clientForModal.verification.document_type
            : 'OP',
        document_number: this.clientForModal.verification.document_number,
        expiration_date: this.clientForModal.verification.expiration_date,
      };
      http.post('/profile/verification/' + self.client.uuid + '/edit', body).then((response) => {
        self.client = response.data.client;
        if (!response.data.client.address) {
          self.client.address = {...EMPTY_CLIENT.address};
        }
        self.toggleVerificationModal();
      });
    },

    updatePassword() {
      let self = this;
      let body = {
        password: this.password,
        password_confirmation: this.password_confirmation,
      };

      http.post('/password/' + self.client.uuid + '/change', body).then((response) => {
      });
      self.togglePasswordModal();
    },

    handleClientPasswordChangeFormSend() {
      this.updatePassword();
      return;
    },

    handleClientInformationFormSend() {
      this.editClientInformation();
      return;
    },

    handleClientAddressFormSend() {
      this.editClientAddress();
      return;
    },

    handleClientVerificationFormSend() {
      this.editClientVerification();
      return;
    },

    toggleModal() {
      const body = document.querySelector('body');
      this.modalActive = !this.modalActive;
      this.modalActive
          ? body.classList.add('modal-open')
          : body.classList.remove('modal-open');
      setTimeout(() => (this.modalShow = !this.modalShow), 10);
    },

    toggleAddressModal() {
      const body = document.querySelector('body');
      this.modalAddressActive = !this.modalAddressActive;
      this.modalAddressActive
          ? body.classList.add('modal-open')
          : body.classList.remove('modal-open');
      setTimeout(() => (this.modalAddressShow = !this.modalAddressShow), 10);
    },

    toggleVerificationModal() {
      const body = document.querySelector('body');
      this.modalVerificationActive = !this.modalVerificationActive;
      this.modalVerificationActive
          ? body.classList.add('modal-open')
          : body.classList.remove('modal-open');
      setTimeout(() => (this.modalVerificationShow = !this.modalVerificationShow), 10);
    },

    togglePasswordModal() {
      const body = document.querySelector('body');
      this.modalPasswordActive = !this.modalPasswordActive;
      this.modalPasswordActive
          ? body.classList.add('modal-open')
          : body.classList.remove('modal-open');
      setTimeout(() => (this.modalPasswordShow = !this.modalPasswordShow), 10);
      if (!this.modalPasswordActive) {
        this.$refs.clientPasswordChangeForm.resetForm();
      }
    },

    formatDate(date) {
      let d = new Date(date);
      return (
          ('00' + d.getDate()).slice(-2) +
          '. ' +
          ('00' + (d.getMonth() + 1)).slice(-2) +
          '. ' +
          d.getFullYear()
      );
    },
  },
};
</script>

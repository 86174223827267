import axios from 'axios';
import authHeader from './services/auth-header';
/* import TokenService from './services/token-service'; */
import Swal from 'sweetalert2/dist/sweetalert2'

let baseURL = import.meta.env.VITE_API_URL;
if(!baseURL || import.meta.env.PROD === true) {
    baseURL = 'https://waipal.com/api';
}

let myAxios = axios.create({
    baseURL: baseURL,
    headers: authHeader()
});


myAxios.interceptors.response.use(function (response) {
    if (response.status == 200 && response.data && response.data.message && response.data.message.length > 0) {
        Swal.fire({
            type: 'success',
            icon: 'success',
            text: response.data.message
        });
    }

    return response;
}, async function (error) {
    if (error.response) {
        let response = error.response;
        const code = response.status;
        const errorMessage = response.data.message;
        const originalConfig = error.config;


        if(errorMessage) {
            Swal.fire({
                type: 'error',
                icon: 'error',
                text: response.data.message
            });
        }

    }
    return Promise.reject(error);
});


export default myAxios;
